import { css } from '@emotion/core';

import headingStyle from '@/src/shared/components/Heading/style';
import linkStyle from '@/src/shared/components/Link/style';
import { bp, colors } from '@/styles';

const titleStyle = [headingStyle.h['2'], headingStyle.weight.bold];

export default css`
  font-size: 1.6rem;
  line-height: 1.25;
  color: ${colors.secondaryGray};
  font-family: 'Lato', sans-serif !important;
  /* div {
    max-width: 832px;
    margin: 0 auto;
  } */
  .publicationTitle,
  .newsReleaseTitle {
    ${titleStyle};
    @media print {
      font-size: 2.4rem;
    }
  }
  .meetingTitle {
    ${titleStyle};
    display: none;
    @media print {
      display: block;
    }
  }
  .publicationTopicHeading,
  .publicationInformationHeading,
  .publicationDateHeading,
  .newsReleaseTopicHeading,
  .meetingInformationHeading,
  .meetingDateHeading,
  .datelabel {
    font-weight: bold;
  }
  ul,
  ol {
    margin-left: 32px;
  }
  .lineBreak {
    display: block;
    margin-bottom: 16px;
  }
  @media print {
    padding: 0 16px;
  }
  cite {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.67;
    color: ${colors.secondaryGrayDark} !important;
  }
  blockquote {
    font-weight: bold;
    font-size: 2rem;
    max-width: 80%;
    padding-left: 24px;
    overflow: hidden;
    height: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    border-left: 6px solid ${colors.primaryRed};
    padding-top: 12px;
    padding-bottom: 12px;
    color: ${colors.secondaryGrayDark} !important;
    p {
      font-size: 2rem;
      cite {
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.67;
        color: ${colors.secondaryGrayDark} !important;
      }
      /* &:last-child {
        font-size: 1.6rem;
      } */
    }
  }
  /* h1 {
    font-size: 3.6rem !important;
    line-height: 4.8rem !important;
  } */
  h1 {
    font-size: 2.8rem !important;
    line-height: 3.6rem !important;
  }
  /* h2 {
    font-size: 2.8rem !important;
    line-height: 3.6rem !important;
  } */
  h2 {
    font-size: 2.4rem !important;
    line-height: 3.2rem !important;
  }
  /* h3 {
    font-size: 2rem !important;
    line-height: 3.2rem !important;
  } */
  h3 {
    font-size: 2rem !important;
    line-height: 2.8rem !important;
  }
  h4 {
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
  }
  h5 {
    font-size: 1.2rem !important;
    line-height: 2rem !important;
    text-transform: uppercase;
  }
  h6 {
    font-size: 1.2rem !important;
    line-height: 2rem !important;
  }
  a {
    ${linkStyle.link};
    display: inline;
  }
  .label {
    text-decoration: underline;
  }
  img[title='Member Only'] {
    display: inline;
  }
  .fs-10 {
    font-size: 10px !important;
  }
  .fs-12 {
    font-size: 12px !important;
  }
  .fs-14 {
    font-size: 14px !important;
  }
  .fs-16 {
    font-size: 16px !important;
  }
  .fs-18 {
    font-size: 18px !important;
  }
  .fs-20 {
    font-size: 20px !important;
  }
  .fs-22 {
    font-size: 22px !important;
  }
  .fs-24 {
    font-size: 24px !important;
  }
  .fs-26 {
    font-size: 26px !important;
  }
  .fs-28 {
    font-size: 28px !important;
  }

  .ta-center {
    text-align: center !important;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .ta-right {
    text-align: right !important;
    img {
      margin-left: auto;
    }
  }
  .ta-left {
    text-align: left !important;
  }

  .background-yellow {
    background-color: yellow;
  }

  .lst-disc {
    list-style-type: disc !important;
  }
  .lst-circle {
    list-style-type: circle !important;
  }
  .lst-square {
    list-style-type: square !important;
  }
  .lst-decimal {
    list-style-type: decimal !important;
  }
  .lst-decimal-leading-zero {
    list-style-type: decimal-leading-zero !important;
  }
  .lst-lower-roman {
    list-style-type: lower-roman !important;
  }
  .lst-upper-roman {
    list-style-type: upper-roman !important;
  }
  .lst-lower-greek {
    list-style-type: lower-greek !important;
  }
  .lst-lower-latin {
    list-style-type: lower-latin !important;
  }
  .lst-upper-latin {
    list-style-type: upper-latin !important;
  }
  .lst-armenian {
    list-style-type: armenian !important;
  }
  .lst-georgian {
    list-style-type: georgian !important;
  }
  .lst-lower-alpha {
    list-style-type: lower-alpha !important;
  }
  .lst-upper-alpha {
    list-style-type: upper-alpha !important;
  }
  .lst-none {
    list-style-type: none !important;
  }

  .fw-100 {
    font-weight: 100 !important;
  }
  .fw-200 {
    font-weight: 200 !important;
  }
  .fw-300 {
    font-weight: 300 !important;
  }
  .fw-400 {
    font-weight: 400 !important;
  }
  .fw-500 {
    font-weight: 500 !important;
  }
  .fw-600 {
    font-weight: 600 !important;
  }
  .fw-700 {
    font-weight: 700 !important;
  }
  .fw-800 {
    font-weight: 800 !important;
  }
  .fw-900 {
    font-weight: 900 !important;
  }
  .fw-lighter {
    font-weight: lighter !important;
  }
  .fw-normal {
    font-weight: normal !important;
  }
  .fw-bold {
    font-weight: bold !important;
  }
  .fw-bolder {
    font-weight: bolder !important;
  }

  .td-underline {
    text-decoration: underline !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  .mb-4 {
    margin-bottom: 4px !important;
  }
  .mb-6 {
    margin-bottom: 6px !important;
  }
  .mb-8 {
    margin-bottom: 8px !important;
  }
  .mb-10 {
    margin-bottom: 10px !important;
  }
  .mb-12 {
    margin-bottom: 12px !important;
  }
  .mb-14 {
    margin-bottom: 14px !important;
  }
  .mb-16 {
    margin-bottom: 16px !important;
  }
  .mb-18 {
    margin-bottom: 18px !important;
  }
  .mb-20 {
    margin-bottom: 20px !important;
  }
  .mb-22 {
    margin-bottom: 22px !important;
  }
  .mb-24 {
    margin-bottom: 24px !important;
  }

  .mt-4 {
    margin-top: 4px !important;
  }
  .mt-6 {
    margin-top: 6px !important;
  }
  .mt-8 {
    margin-top: 8px !important;
  }
  .mt-10 {
    margin-top: 10px !important;
  }
  .mt-12 {
    margin-top: 12px !important;
  }
  .mt-14 {
    margin-top: 14px !important;
  }
  .mt-16 {
    margin-top: 16px !important;
  }
  .mt-18 {
    margin-top: 18px !important;
  }
  .mt-20 {
    margin-top: 20px !important;
  }
  .mt-22 {
    margin-top: 22px !important;
  }
  .mt-24 {
    margin-top: 24px !important;
  }

  .ml-4 {
    margin-left: 4px !important;
  }
  .ml-6 {
    margin-left: 6px !important;
  }
  .ml-8 {
    margin-left: 8px !important;
  }
  .ml-10 {
    margin-left: 10px !important;
  }
  .ml-12 {
    margin-left: 12px !important;
  }
  .ml-14 {
    margin-left: 14px !important;
  }
  .ml-16 {
    margin-left: 16px !important;
  }
  .ml-18 {
    margin-left: 18px !important;
  }
  .ml-20 {
    margin-left: 20px !important;
  }
  .ml-40 {
    margin-left: 40px !important;
  }
  .ml-60 {
    margin-left: 60px !important;
  }
  .ml-80 {
    margin-left: 80px !important;
  }
  .ml-100 {
    margin-left: 100px !important;
  }
  .ml-120 {
    margin-left: 120px !important;
  }
  .ml-140 {
    margin-left: 140px !important;
  }
  .ml-160 {
    margin-left: 160px !important;
  }
  .ml-180 {
    margin-left: 180px !important;
  }
  .ml-200 {
    margin-left: 200px !important;
  }
  .ml-220 {
    margin-left: 220px !important;
  }
  .ml-240 {
    margin-left: 240px !important;
  }
  .ml-260 {
    margin-left: 260px !important;
  }

  .mr-4 {
    margin-right: 4px !important;
  }
  .mr-6 {
    margin-right: 6px !important;
  }
  .mr-8 {
    margin-right: 8px !important;
  }
  .mr-10 {
    margin-right: 10px !important;
  }
  .mr-12 {
    margin-right: 12px !important;
  }
  .mr-14 {
    margin-right: 14px !important;
  }
  .mr-16 {
    margin-right: 16px !important;
  }
  .mr-18 {
    margin-right: 18px !important;
  }
  .mr-20 {
    margin-right: 20px !important;
  }
  .mr-40 {
    margin-right: 40px !important;
  }
  .mr-60 {
    margin-right: 60px !important;
  }
  .mr-80 {
    margin-right: 80px !important;
  }
  .mr-100 {
    margin-right: 100px !important;
  }
  .mr-120 {
    margin-right: 120px !important;
  }
  .mr-140 {
    margin-right: 140px !important;
  }
  .mr-160 {
    margin-right: 160px !important;
  }
  .mr-180 {
    margin-right: 180px !important;
  }
  .mr-200 {
    margin-right: 200px !important;
  }
  .mr-220 {
    margin-right: 220px !important;
  }
  .mr-240 {
    margin-right: 240px !important;
  }
  .mr-260 {
    margin-right: 260px !important;
  }

  .float-left:not(img) {
    float: left;
  }
  .float-right:not(img) {
    float: right;
  }
  .float-none:not(img) {
    float: none;
  }

  @media ${bp.sm} {
    img.float-left {
      float: left;
    }
    img.float-right {
      float: right;
    }
    img.float-none {
      float: none;
    }
  }

  p {
    margin: 0;
  }
  .MsoTableGrid {
    border-radius: 2px;
    border: none !important;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  .MsoTableGrid td {
    padding: 16px 24px;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: 1px solid #dad9d7 !important;
    overflow-wrap: break-word;
    font-size: 16px;
    line-height: 1.5;
    margin-left: -1px;
  }
  .MsoTableGrid td:first-child {
    padding-left: 32px;
    margin-left: 0;
  }
  .MsoTableGrid td:last-child {
    padding-right: 32px;
  }
  .MsoTableGrid tr:last-child td {
    border-bottom: none !important;
  }
  @media (max-width: ${bp.sizes.sm}px) {
    .c-table-mobile tbody,
    .c-table-mobile tr,
    .c-table-mobile td {
      display: block;
      width: 100% !important;
    }
    .c-table-mobile td {
      margin: 0 0 12px;
    }
    .c-table-mobile td[style='width: 10px;'] {
      display: none !important;
    }
  }
  .c-columns-2 {
    @media ${bp.sm} {
      display: flex;
      flex-wrap: wrap;
      margin: -16px;
    }
  }
  .c-columns-2 .c-column {
    margin: 0 0 16px;
    @media ${bp.sm} {
      flex: 1;
      flex-basis: calc(100% / 2 - 32px);
      margin: 16px;
      max-width: calc(100% / 2 - 32px);
    }
  }
  .c-columns-3 {
    @media ${bp.sm} {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
  }
  .c-columns-3 .c-column {
    margin: 0 0 16px;
    img {
      margin: 0 auto;
    }
    @media ${bp.sm} {
      flex: 1;
      flex-basis: calc(100% / 2 - 32px);
      margin: 0 10px 36px;
      max-width: calc(100% / 2 - 32px);
    }
    @media ${bp.md} {
      flex-basis: calc(100% / 3 - 32px);
      max-width: calc(100% / 3 - 32px);
    }
  }
  .c-column {
    iframe {
      max-width: 100%;
    }
    a:has(img) {
      appearance: auto !important;
    }
  }

  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
  @media ${bp.sm} {
    .mobile-only {
      display: none;
    }
    .desktop-only {
      display: block;
    }
  }
`;
